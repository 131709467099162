/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types';
import { ModelFigureParameters } from '@/components/pages/model-account/my-account-figure-parameters/model-account-my-account-figure-parameters.interface'

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountFigureParametersPage extends Vue {
	@State('modelAccount', {namespace}) modelAccount: ModelAccountState | undefined;
	@Getter('getModelSettingsUsername', {namespace}) getModelSettingsUsername: any;
	@Getter('getModelSettingsGrowth', {namespace}) getModelSettingsGrowth: any;
	@Getter('getModelSettingsBodyType', {namespace}) getModelSettingsBodyType: any;
	@Getter('getModelSettingsEyeColor', {namespace}) getModelSettingsEyeColor: any;
	@Getter('getModelSettingsButtSize', {namespace}) getModelSettingsButtSize: any;
	@Getter('getModelSettingsWeight', {namespace}) getModelSettingsWeight: any;
	@Getter('getModelSettingsHairColor', {namespace}) getModelSettingsHairColor: any;
	@Getter('getModelSettingsBreastSize', {namespace}) getModelSettingsBreastSize: any;
	@Getter('getModelSettingsIntimateHaircut', {namespace}) getModelSettingsIntimateHaircut: any;
	@Action('updateProfileInformation', {namespace}) updateProfileInformation: any;
	@Action('getProfileInformation', {namespace}) getProfileInformation: any;

	figureParameters: ModelFigureParameters = {
		growth: '',
		bodyType: '',
		eyeColor: '',
		buttSize: '',
		weight: '',
		hairColor: '',
		breastSize: '',
		intimateHaircut: ''
	}
	$refs!: {
		refGrowth: HTMLInputElement,
		refBodyType: HTMLInputElement,
		refEyeColor: HTMLInputElement,
		refButtSize: HTMLInputElement,
		refWeight: HTMLInputElement,
		refHairColor: HTMLInputElement,
		refBreastSize: HTMLInputElement,
		refIntimateHaircut: HTMLInputElement,
	}

	handleClickElement(e: Event) {
		const { target } = e;
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('growth')) {
			this.$refs.refGrowth.value = (target as HTMLLIElement).innerText;
			this.figureParameters.growth = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('body-type')) {
			this.$refs.refBodyType.value = (target as HTMLLIElement).innerText;
			this.figureParameters.bodyType = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('eye-color')) {
			this.$refs.refEyeColor.value = (target as HTMLLIElement).innerText;
			this.figureParameters.eyeColor = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('butt-size')) {
			this.$refs.refButtSize.value = (target as HTMLLIElement).innerText;
			this.figureParameters.buttSize = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('weight')) {
			this.$refs.refWeight.value = (target as HTMLLIElement).innerText;
			this.figureParameters.weight = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('hair-color')) {
			this.$refs.refHairColor.value = (target as HTMLLIElement).innerText;
			this.figureParameters.hairColor = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('breast-size')) {
			this.$refs.refBreastSize.value = (target as HTMLLIElement).innerText;
			this.figureParameters.breastSize = (target as HTMLLIElement).innerText;
		}
		if ((target as HTMLLIElement).nodeName === 'LI' && (target as HTMLLIElement).className.includes('intimate-haircut')) {
			this.$refs.refIntimateHaircut.value = (target as HTMLLIElement).innerText;
			this.figureParameters.intimateHaircut = (target as HTMLLIElement).innerText;
		}
	}

	changeSelected() {
		document.addEventListener('click', this.handleClickElement);
	}

	onSubmit() {
		this.updateProfileInformation({
			growth: this.figureParameters.growth,
			bodyType: this.figureParameters.bodyType,
			eyeColor: this.figureParameters.eyeColor,
			buttSize: this.figureParameters.buttSize,
			weight: this.figureParameters.weight,
			hairColor: this.figureParameters.hairColor,
			breastSize: this.figureParameters.breastSize,
			intimateHaircut: this.figureParameters.intimateHaircut
		});
		document.removeEventListener('click',  this.handleClickElement);
	}

	mounted() {
		this.getProfileInformation();
		this.$refs.refGrowth.value = this.getModelSettingsGrowth;
		this.$refs.refBodyType.value = this.getModelSettingsBodyType;
		this.$refs.refEyeColor.value = this.getModelSettingsEyeColor;
		this.$refs.refButtSize.value = this.getModelSettingsButtSize;
		this.$refs.refWeight.value = this.getModelSettingsWeight;
		this.$refs.refHairColor.value = this.getModelSettingsHairColor;
		this.$refs.refBreastSize.value = this.getModelSettingsBreastSize;
		this.$refs.refIntimateHaircut.value = this.getModelSettingsIntimateHaircut;
		this.figureParameters = {
			growth: this.getModelSettingsGrowth,
			bodyType: this.getModelSettingsBodyType,
			eyeColor: this.getModelSettingsEyeColor,
			buttSize: this.getModelSettingsButtSize,
			weight: this.getModelSettingsWeight,
			hairColor: this.getModelSettingsHairColor,
			breastSize: this.getModelSettingsBreastSize,
			intimateHaircut: this.getModelSettingsIntimateHaircut
		}
	}
}
